/*
 * Button
 */

@layer utilities {

	// Button
	.btn {

		// Basic Classes
		@apply block w-auto text-center transition border-0 border-transparent sm:inline-block focus:outline-0 focus:border-transparent;

		// Sizing
		&.btn-sm { @apply px-3 py-2.5 body-sm font-semibold leading-none rounded-md }
		&.btn-md { @apply px-4 py-3 body-md font-semibold leading-none rounded-md }
		&.btn-lg { @apply px-6 py-4 body-lg font-semibold leading-none rounded-md }

		// Filled Buttons
		&.btn-filled {

			@apply focus:ring-2;

			// Colour
			&.btn-primary { @apply text-white bg-primary-500 hover:bg-primary-600 active:bg-primary-400 focus:ring-primary-500/25 }
			&.btn-secondary { @apply text-white bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-400 focus:ring-secondary-500/25 }
			&.btn-neutral { @apply text-neutral-50 bg-neutral-800 hover:bg-neutral-900 active:bg-neutral-700 focus:ring-neutral-500/25 }
			&.btn-light { @apply text-neutral-800 bg-neutral-50 hover:bg-neutral-100 active:bg-white focus:ring-neutral-500/25 }
		}

		// Line Buttons
		&.btn-line {

			@apply focus:ring-2 dark:text-neutral-50 text-neutral-700;

			// Colour
			&.btn-primary { @apply bg-transparent border-2 border-primary-500 hover:border-primary-600 active:border-primary-400 focus:ring-primary-500/25 }
			&.btn-secondary { @apply bg-transparent border-2 border-secondary-500 hover:border-secondary-600 active:border-secondary-400 focus:ring-secondary-500/25 }
			&.btn-neutral { @apply bg-transparent border-2 border-neutral-800 hover:border-neutral-900 active:border-neutral-700 focus:ring-neutral-500/25 }
			&.btn-light { @apply bg-transparent border-2 border-neutral-50 hover:border-neutral-100 active:border-white focus:ring-neutral-100/25 }
		}

		// Link Buttons
		&.btn-link {

				@apply px-0 py-0 text-left #{!important};

			// Colour
			&.btn-primary { @apply text-primary-500 hover:text-primary-600 active:text-primary-400 focus:text-primary-700 }
			&.btn-secondary { @apply text-secondary-500 hover:text-secondary-600 active:text-secondary-400 focus:text-secondary-700 }
			&.btn-neutral { @apply text-neutral-800 hover:text-neutral-900 active:text-neutral-400 focus:text-neutral-950 }
			&.btn-light { @apply text-neutral-50 hover:text-neutral-100 active:text-neutral-200 focus:text-white }
		}
	}
}
