// ==========================================================================
// Typography
// ==========================================================================

@layer base {

    /* Headings */
    .h1 { @apply font-heading font-bold leading-[1.15] tracking-tight text-[2.25rem] lg:text-[3.25rem] xl:text-[3.75rem] }                  // H1 - 60px
    .h2 { @apply font-heading font-bold leading-tight tracking-tight text-[2rem] md:text-[2.25rem] lg:text-[3rem] }                         // H2 - 48px
    .h3 { @apply font-heading font-bold leading-tight tracking-tight text-[1.875rem] lg:text-[2.25rem] }                                    // H3 - 36px
    .h4 { @apply font-heading font-bold leading-tight tracking-tight text-[1.5rem] lg:text-[1.875rem] }                                     // H4 - 30px
    .h5 { @apply font-heading font-bold leading-tight tracking-tight text-[1.25rem] lg:text-[1.5rem] }                                      // H5 - 24px
    .h6 { @apply font-heading font-bold leading-tight tracking-tight text-[1rem] lg:text-[1.125rem] }                                       // H6 - 18px

    /* Body */
    .body-2xl { @apply font-light leading-normal text-[1.5rem] }                                                      // Body 2xl - 24px
    .body-xl { @apply font-light leading-normal text-[1.25rem] }                                                      // Body xl - 20px
    .body-lg { @apply font-light leading-relaxed text-[1.125rem] }                                                    // Body lg - 18px
    .body-md { @apply font-light leading-relaxed text-[1rem] }                                                        // Body md - 16px
    .body-sm { @apply font-light leading-relaxed text-[0.875rem] }                                                    // Body sm - 14px
    .body-xs { @apply font-light leading-relaxed text-[0.75rem] }                                                     // Body xs - 12px

}

@layer utilities {

    .text-balance { text-wrap: balance }
    .text-nowrap { text-wrap: nowrap }
    .text-wrap { text-wrap: wrap }
    
    .copy {

        @apply space-y-3;

        italic { @apply italic; }

        bold { @apply font-bold; }

        a { @apply font-semibold text-primary-500 transition hover:text-primary-600 active:text-primary-400; }

        &> ul {

            @apply list-disc list-outside ml-[1.2em] space-y-2;

            li::marker { @apply text-primary-500; }
        }

        &> ol {

            @apply list-decimal list-outside ml-[1.2em] space-y-2;

            li::marker { @apply text-primary-500; }
        }
    }

    /* Text Shadow */
    .text-shadow { text-shadow: 0 2px 4px rgba(0,0,0,0.10) }
    .text-shadow-md { text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08) }
    .text-shadow-lg { text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08) }
    .text-shadow-none { text-shadow: none }

}
