/* for AlpineJs */
[x-cloak] {
    display: none !important;
}


body {
    position: relative;
    overflow-x: hidden;
    height: 100%;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    background-color: #fff;
    -webkit-animation: fadein .6s ease-in forwards;
    animation: fadein .6s ease-in forwards;
}

/* Add this */
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* For Webkit browsers */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@layer utilities {

    // Google Maps
    .gm-map {
        @apply w-full h-full;
    }

    // Navigation Container
    .container-nav {  @apply
        px-4 py-4 sm:py-8 sm:px-8 md:py-8  lg:py-12 xl:py-8
        max-w-screen-2xl mx-auto;
    }

    .container-hero {  @apply
        px-4 py-4 sm:py-8 sm:px-8 md:py-10  lg:py-12 xl:py-12
        max-w-screen-2xl mx-auto;
    }

    // Block Container XS
    .container-block-xs {  @apply
        px-4 py-6 md:px-8 md:py-6 lg:py-6
        max-w-screen-xl mx-auto;
    }

    // Block Container SM
    .container-block-sm {  @apply
        px-4 py-8 md:px-8 md:py-10 lg:py-12
        max-w-screen-xl mx-auto;
    }

    // Block Container MD
    .container-block, .container-block-md {  @apply
        px-4 py-8 md:px-8 md:py-12 lg:py-16
        max-w-screen-xl mx-auto;
    }

    // Block Container LG
    .container-block-lg {  @apply
        px-4 py-8 md:px-8 md:py-16 lg:py-24
        max-w-screen-xl mx-auto;
    }


    /* Title Squiggles */
    u {
        @apply no-underline relative z-0 /* whitespace-nowrap */;

        &::after {
            @apply content-[''] absolute h-[0.5em] w-full bottom-[-0.1em] left-0 -z-10 bg-squiggle-underline-z bg-no-repeat;
        }
    }

    
}
