@layer utilities {

    :root, .theme-one {
        --color-accent-50: 250 245 255;
        --color-accent-100: 243 232 255;
        --color-accent-200: 233 213 255;
        --color-accent-300: 216 180 254;
        --color-accent-400: 192 132 252;
        --color-accent-500: 168 85 247;
        --color-accent-600: 147 51 234;
        --color-accent-700: 126 34 206;
        --color-accent-800: 107 33 168;
        --color-accent-900: 88 28 135;
        --color-accent-950: 59 7 100;
    }

    .theme-extra {
        --color-accent-50: 255 241 242;
        --color-accent-100: 255 228 230;
        --color-accent-200: 254 205 211;
        --color-accent-300: 253 164 175;
        --color-accent-400: 251 113 133;
        --color-accent-500: 244 63 94;
        --color-accent-600: 225 29 72;
        --color-accent-700: 190 18 60;
        --color-accent-800: 159 18 57;
        --color-accent-900: 136 19 55;
        --color-accent-950: 76 5 25;
    }
}