/*
 * Input
 */
 
@layer utilities {

    // Textbox Label
    .input-label { @apply block font-semibold body-lg text-neutral-600; }

    // Textbox and Textarea
    .input-text { @apply block w-full px-4 py-3 transition border rounded body-sm bg-neutral-50  text-neutral-400 border-neutral-200 focus:border-primary-300 focus:ring-0 hover:border-primary-200; }

    // Dropdown
    .input-dropdown { @apply block w-full px-4 py-3 transition border rounded body-sm bg-neutral-50 text-neutral-400 border-neutral-200 focus:border-primary-300 focus:ring-0 hover:border-primary-200; }

    // Checkbox
    .input-checkbox { @apply w-6 h-6 transition border rounded-sm bg-neutral-50 border-neutral-200 text-primary-500 hover:bg-primary-50 focus:ring-2 focus:ring-primary-500/50; }

    // Radio Button
    .input-radio { @apply w-6 h-6 transition border rounded-full bg-neutral-50 border-neutral-200 text-primary-500 hover:bg-primary-50 focus:ring-2 focus:ring-primary-500/50; }
    
    // Selector Label
    .input-selector-label { @apply body-md text-neutral-600; }

}